<!--
 * @Description: AKJERRT
 * @Date: 2022-07-08 22:12:44
 * @LastEditTime: 2022-07-14 11:40:06
 * @FilePath: \linkgap-front\src\views\dealer_balance\components\table\ZLAccountTable.vue
-->
<template>
  <a-modal title="账单总览" width="50%" v-model="visible" :footer="null" :maskClosable="false">
    <a-row type="flex" justify="center">
      <a-col class="title"><span class="title-company">江苏艾康迪机电工程有限公司</span> 对账单 </a-col>
    </a-row>
    <br />
    <a-row type="flex" justify="space-between">
      <a-col :span="16">客户名称： 江苏艾康迪机电工程有限公司 </a-col>
      <a-col :span="6">账单周期: 20220601-20220630</a-col>
    </a-row>
    <br />
    <a-row type="flex" justify="space-between">
      <a-col class="annotation" type="flex" justify="left">注：上期余额+本期增加-本期发货=本期结余</a-col>
    </a-row>
    <br />
    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="false"
    >
      <span slot="QK" slot-scope="text">
        <span class="TextRed">{{ text }}</span>
      </span>
    </a-table>
    <br />
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      tableLoading: false,
      visible: false,
      columns: [
        {
          title: '项目',
          dataIndex: 'XM',
          key: 'XM',
          align: 'center',
          width: 100,
        },
        {
          title: '欠款余额',
          align: 'center',
          className: 'headerYellow',
          children: [
            {
              title: '欠款',
              dataIndex: 'QK',
              key: 'QK',
              align: 'center',
              width: 100,
              className: 'headerTextRed',
              scopedSlots: { customRender: 'QK' },
            },
          ],
        },
        {
          title: '可使用额度',
          align: 'center',
          className: 'headerBlue',
          children: [
            {
              title: '上期余额',
              dataIndex: 'SQYE',
              key: 'SQYE',
              align: 'center',
              width: 100,
            },
            {
              title: '本期增加',
              dataIndex: 'BQZJ',
              key: 'BQZJ',
              align: 'center',
              width: 100,
            },
            {
              title: '本期失效额度',
              dataIndex: 'BQSXED',
              key: 'BQSXED',
              align: 'center',
              width: 100,
            },
            {
              title: '本期发货',
              dataIndex: 'BQFH',
              key: 'BQFH',
              align: 'center',
              width: 100,
            },
            {
              title: '可使用结余',
              dataIndex: 'KSYJY',
              key: 'KSYJY',
              align: 'center',
              width: 100,
            },
          ],
        },
      ],
      tableData: [],
    }
  },
  mounted() {
    this.tableData = [
      {
        XM: '资金',
        QK: 60,
        SQYE: 10,
        BQZJ: 100,
        BQSXED: 0,
        BQFH: 40,
        KSYJY: 70,
      },
      {
        XM: '信用额度',
        QK: 60,
        SQYE: 10,
        BQZJ: 100,
        BQSXED: 0,
        BQFH: 40,
        KSYJY: 70,
      },
      {
        XM: '合计',
        QK: 60,
        SQYE: 10,
        BQZJ: 100,
        BQSXED: 0,
        BQFH: 40,
        KSYJY: 70,
      },
      {
        XM: '折扣额',
        QK: 60,
        SQYE: 10,
        BQZJ: 100,
        BQSXED: 0,
        BQFH: 40,
        KSYJY: 70,
      },
    ]
  },
  methods: {
    setRowData() {
      this.visible = true
    },
    toSubmit() {
      this.visible = false
    },
    cancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
.footer-btns {
  text-align: right;
}
.title {
  font-size: 1.5rem;
  font-weight: 900;
  .title-company {
    border-bottom: 1px double black;
  }
}
::v-deep .ant-table-thead > tr > th.headerYellow {
  background-color: #fff2cc;
}
::v-deep .ant-table-thead > tr > th.headerBlue {
  background-color: #ddebf7;
}
::v-deep .ant-table-thead > tr > th.headerTextRed {
  color: red;
  font-weight: 600;
}
.TextRed {
  color: red;
}
.annotation {
  color: orange;
  font-size: 12px;
}
</style>
